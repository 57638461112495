<script setup>
import { computed } from 'vue'

const emit = defineEmits(['update:checked'])

const props = defineProps({
  checked: {
    type: [Array, Boolean, Function],
    default: false,
  },
  value: {
    type: [String, Number],
    default: null,
  },
})

const proxyChecked = computed({
  get() {
    return props.checked
  },

  set(val) {
    emit('update:checked', val)
  },
})
</script>

<template>
  <input
    v-model="proxyChecked"
    type="checkbox"
    :value="value"
    class="rounded text-orange hover-green shadow-sm p-3 border-black focus:border-2 focus:ring-2 focus:ring-offset-0 focus:ring-yellow-500 checked:checkbox-custom-bg-image"
    style="appearance: none"
  />
</template>
